<template>
  <div class="container">
    <el-card class="box-card">
      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <el-table-column align="center" label="讲师名称" prop="lecturerName"></el-table-column>
        <el-table-column align="center" label="课程名称" prop="courseName"></el-table-column>
        <el-table-column align="center" label="课程描述" prop="courseDesc" width="200"></el-table-column>
        <el-table-column align="center" label="课程封面图" width="200">
          <template slot-scope="scope">
            <el-image :src="scope.row.courseImg" fit="fill"></el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" label="课程分类" prop="courseName">
          <template slot-scope="scope">
            <el-tag :type="scope.row.type == 0 ? '' : scope.row.type == 1 ? 'success' : 'warning'" disable-transitions>
              {{ scope.row.type == 0 ? '免费课程' : scope.row.type == 1 ? '收费课程' : 'VIP课程' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="课程标签" prop="label"></el-table-column>
        <template v-if="info.type != 0">
          <el-table-column align="center" label="课程原价格" prop="coursePrice"></el-table-column>
          <el-table-column align="center" label="是否免费试听">
            <template slot-scope="scope">
              <el-tag :type="scope.row.isAudition == 0 ? 'success' : 'danger'">
                {{ scope.row.isAudition == 0 ? '是' : '否' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="是否开启活动">
            <template slot-scope="scope">
              <el-tag :type="scope.row.isActivity == 0 ? 'success' : 'danger'">
                {{ scope.row.isActivity == 0 ? '是' : '否' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="活动价格" v-if="info.isActivity == 0"
                           prop="courseActivityPrice"></el-table-column>
          <el-table-column align="center" label="开始时间" v-if="info.isActivity == 0">
            <template slot-scope="scope">
              {{ $moment(scope.row.activityStartTime).format("YYYY-MM-DD") }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="结束时间" v-if="info.isActivity == 0">
            <template slot-scope="scope">
              {{ $moment(scope.row.activityEndTime).format("YYYY-MM-DD") }}
            </template>
          </el-table-column>
        </template>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 0 ? 'success' : 'danger'">{{ scope.row.status == 0 ? '启用' : '停用' }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="info">
        <div class="layout title">课程详情：</div>
        <div style="border-top: 1px solid #EBEEF5;padding:20px">
          <div v-html="info.courseText"></div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {getCourseId} from "../../apis/course";

export default {
  data() {
    return {
      tableData: [],
      info: {},
      loading: false,
    };
  },
  activated() {
    this.tableData = [];
    const id = this.$route.query.id;
    this.getDetail(id);
  },
  methods: {
    async getDetail(id) {
      this.loading = true;
      const res = await getCourseId({id});
      this.tableData.push(res.data);
      this.info = res.data;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.el-table {
  margin-bottom: 0;
}

.list {
  margin-bottom: 40px;
  align-items: flex-start;

  .list-item {
    display: flex;
    flex: 1;

    .img {
      width: 300px;
      height: 100px;
    }
  }

  .label {
    display: inline-block;
    width: 140px;
    margin-right: 10px;
    text-align: right;
    color: #666;
  }
}

.info {
  border: 1px solid #ebeef5;
  border-top: 0;
  color: #909399;
  font-size: 14px;
  font-weight: 600;

  .title {
    width: 130px;
    height: 48px;
  }
}
</style>